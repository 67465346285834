import * as React from "react"
import { Component } from "react"
import Translation from "../translation/translation"
import "./footer.scss"
import MyContext from "../context/myContext"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class Footer extends Component {
  render() {
    return (
      <footer>
        <p>
          <Translation trl="need_help" />{" "}
          <a
            onClick={() =>
              navigate("/faq", { state: { lang: this.props.lang } })
            }
          >
            <Translation trl="check_faq" />
          </a>
        </p>
        <p>
          <Translation trl="all_rights" />
        </p>
        <p>
          <a
            href={
              this.props.lang === "nl"
                ? `../../NL_QVAX_Privacy_Policy_021221.pdf`
                : this.props.lang === "en"
                ? `../../EN_QVAX_Privacy_Policy_021221.pdf`
                : this.props.lang === "de"
                ? `../../DE-QVAX-Datenschutzregelung.pdf`
                : `../../FR_QVAX_Politique_de_confidentialité_021221.pdf`
            }
            target="_blank"
          >
            <Translation trl="privacy" />
          </a>
        </p>
        <p>
          <a
            href={
              this.props.lang === "nl"
                ? `../../NL - Algemene gebruiksvoorwaarden van een website QVAX.pdf`
                : this.props.lang === "de"
                ? `../../QVAX-Allgemeine_Bedingungen_für_die_Nutzung.pdf`
                : this.props.lang === "en"
                ? `../../EN-Website_general_terms_and_conditions_QVAX.pdf`
                : `../../FR - Conditions générales d'utilisation d'un site internet QVAX.pdf`
            }
            target="_blank"
          >
            <Translation trl="cgu" />
          </a>
        </p>
      </footer>
    )
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

Footer.contextType = MyContext
export default connect(mapStateToProps)(Footer)
