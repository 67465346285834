import React, { Component } from "react";
import MyContext from "../context/myContext";
import Translation from "../translation/translation";
import { logIn, changeLang } from "../../reducers/actions/index";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import "./password.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class PasswordPage extends Component {
  session
  constructor(props, context) {
    super(props, context)
    this.state = {
      showSignupForm: true,
      client: this.context.sdkClient,
      mailSent: false,
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.langSaved) {
      this.props.changeLang(this.props.langSaved)
    }
  }

  render() {
    return (
      <div className="pwd_page">
        {!this.state.mailSent && (
          <div className="titles">
            {/* <span onClick={() => this.goBack()}><ArrowBackIosIcon/>Back to region selection</span> */}
            <h1>
              <Translation trl="pwd_forgot" />
            </h1>

            <h2>
              <Translation trl="pwd_forgot_title" />
            </h2>
            <h4 className="msg-sub">
          <Translation trl="rajout_message_alert" />
        </h4>
          </div>
        )}
        {this.state.mailSent && (
          <div className="titles">
            {/* <span onClick={() => this.goBack()}><ArrowBackIosIcon/>Back to region selection</span> */}
            <h1>
              <Translation trl="link_sent" />
            </h1>
            <h4 className="msg-sub">
          <Translation trl="rajout_message_alert" />
        </h4>
            {/* <h2><Translation trl="link_sent_to"/>{this.state.mail}</h2> */}
          </div>
        )}
        <form>
          {!this.state.mailSent && (
            <div
              className={
                this.state.nissError
                  ? "form_input error"
                  : this.state.nissError === false
                  ? "form_input success"
                  : "form_input"
              }
            >
              <label>
                <Translation trl="niss" />
              </label>
              <input
                value={this.state.idNumber}
                placeholder="XX.XX.XX-XXX.XX"
                onChange={e => this.changeNiss(e.target.value)}
                onBlur={e => this.checkNISS(e.target.value)}
              />
            </div>
          )}
          {this.state.mailSent && (
            <div>
              <h3>
                <Translation trl="mail_not_received" />
              </h3>

            
             
              <p>
                <Translation trl="mail_not_received_solution" />
              </p>
            </div>
          )}
        </form>
        <div className="actions">
          {!this.state.mailSent && (
            <button
              id="nextStepRegister"
              className={this.isButtonValid() ? "primary" : "primary disabled"}
              onClick={e => this.onSubmit(e)}
            >
              <span>
                <Translation trl="send" />
              </span>
            </button>
          )}
        </div>
      </div>
    )
  }

  goBack() {
    if (this.isClientSide()) {
      localStorage.removeItem("qvax-selected-region")
      navigate("/region", { state: { lang: this.props.lang } })
    }
  }

  checkNISS(value) {
    if (value.length === 15) {
      const niss1 = +value
        .replace(/\./g, "")
        .replace("-", "")
        .substring(0, 9)
      const niss2 = +(
        "2" +
        value
          .replace(/\./g, "")
          .replace("-", "")
          .substring(0, 9)
      )
      const verif = +value.substring(13)
      if (97 - (niss1 % 97) === verif || 97 - (niss2 % 97) === verif) {
        this.setState({ nissError: false })
      } else {
        this.setState({ nissError: true })
      }
    } else {
      if (value.length > 0) {
        this.setState({ nissError: true })
      } else {
        this.setState({ nissError: undefined })
      }
    }
  }

  formatNiss(value) {
    const numbers = value.replace(/\D/g, "")
    const numb = numbers.split("")
    let inserted = 0
    numbers.split("").forEach((n, i) => {
      if (i === 2 || i === 4 || i === 9) {
        numb.splice(i + inserted, 0, ".")
        inserted += 1
      }
      if (i === 6) {
        numb.splice(i + inserted, 0, "-")
        inserted += 1
      }
    })
    return numb.join("")
  }

  changeNiss(value) {
    const lastChar = value[value.length - 1]
    const reg = /^\d+$/
    if (
      value.length <= 15 &&
      reg.test(lastChar) &&
      (!this.state.idNumber || value.length >= this.state.idNumber.length)
    ) {
      this.setState({ idNumber: this.formatNiss(value), nissError: undefined })
      if (value.length === 15) {
        this.checkNISS(value)
      }
    } else {
      if (value.length === 0) {
        this.setState({ idNumber: undefined })
      } else {
        if (this.state.idNumber && value.length < this.state.idNumber.length) {
          this.setState({ idNumber: value, nissError: undefined })
        } else {
          this.setState({ idNumber: this.state.idNumber })
        }
      }
    }
  }

  isButtonValid() {
    return this.state.idNumber && !this.state.nissError
  }

  onSubmit(event) {
    event.preventDefault()
    this.state.client.sessionService
      .doPasswordReset(this.state.idNumber.replace(/\./g, "").replace("-", ""))
      .then(res => {
        this.setState({ mailSent: true })
      })
      .catch(error => {})
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn)
    const dateNow = new Date()
    return expireDate > dateNow
  }

  isClientSide() {
    return typeof window !== "undefined"
  }
}

PasswordPage.contextType = MyContext
const mapStateToProps = state => ({
  login: state.login,
  lang: state.lang,
})

const mapDispatchToProps = () => {
  return {
    logIn,
    changeLang,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(PasswordPage)
