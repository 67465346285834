import React from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Translation from "../translation/translation";
import MyContext from "../context/myContext";
import { Link, navigate } from "gatsby";
import "./navbar.scss";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LangSelector from "../langSelector/langSelector";
import logo from "../../images/logo_qvax.svg";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';
class Navbar extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropdownOpen: false,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("SeatersEmbeddedAuthToken")) {
        const parsedSession = JSON.parse(
          localStorage.getItem("SeatersEmbeddedAuthToken")
        );
        const session = {
          expirationDate: parsedSession.expiresOn,
          token: parsedSession.token,
        };
        if (this.isSessionValid(parsedSession)) {
          this.context.sdkClient.sessionService.setSession(session);
          this.context.sdkClient.fanService.fan().then(fan => {
            return this.context.sdkClient.fanService
              .customProfile(
                FG_ID
              )
              .then(custom => {
                this.setState({
                  logged: true,
                  fanName: fan.firstName + " " + fan.lastName,
                });
              });
          });
        }
      }
    }
  }

  render() {
    return (
      <div className="navbar">
        <div className="left">
          <img src={logo} onClick={() => this.goHome()} />
          <LangSelector />
        </div>

        <div className="right">
          {false && !this.props.hideHow && !this.props.logout && (
            <button
              className="ghost"
              onClick={() =>
                navigate("/how", { state: { lang: this.props.lang } })
              }
            >
              <span>
                <Translation trl="how" />
              </span>
              <HelpOutlineIcon />
            </button>
          )}
          {!this.props.hideAuth && !this.props.signup && !this.props.logout && (
            <button
              onClick={() =>
                navigate("/login", { state: { lang: this.props.lang } })
              }
            >
              <span>
                <Translation trl="login" />
              </span>
              <PermIdentityIcon />
            </button>
          )}
          {!this.props.hideAuth && this.props.signup && !this.props.logout && (
            // If we are on LOGIN page, user will be redirected to HOW page
            <button
              onClick={() =>
                navigate(
                  location.pathname.includes("login") ? "/how" : "/register",
                  { state: { lang: this.props.lang } }
                )
              }
            >
              <span>
                <Translation trl="signup" />
              </span>
              <PermIdentityIcon />
            </button>
          )}
          {(this.props.logout || this.state.logged) && (
            <div className="profile">
              <div className="select">
                <span className={this.state.dropdownOpen ? "active" : ""}>
                  <Translation trl="my_profile" />
                </span>
                <div
                  className="icon-container"
                  onClick={() => this.toggleDropdown()}
                >
                  {this.state.dropdownOpen && <ExpandLessIcon />}
                  {!this.state.dropdownOpen && <ExpandMoreIcon />}
                </div>
              </div>
              <div
                className={
                  this.state.dropdownOpen ? "dropdown active" : "dropdown"
                }
              >
                <div
                  onClick={() =>
                    navigate("/profile", { state: { lang: this.props.lang } })
                  }
                >
                  <Translation trl="settings" />
                  <SettingsIcon />
                </div>
                <div onClick={() => this.logout()}>
                  <Translation trl="logout" /> <ExitToAppIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  goHome() {
    if (typeof window !== "undefined") {
      if (this.props.logout || this.state.logged) {
        navigate("/", { state: { lang: this.props.lang } });
      } else {
        localStorage.removeItem("qvax-selected-region");
        navigate("/region");
      }
    }
  }

  logout() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("qvax-session");
      localStorage.removeItem("SeatersEmbeddedAuthToken");
      navigate("/login");
    }
  }

  isSessionValid(session) {
    const expireDate = new Date(session.expiresOn);
    const dateNow = new Date();
    return expireDate > dateNow;
  }
}

Navbar.contextType = MyContext;
const mapStateToProps = state => ({
  lang: state.lang,
});

export default connect(mapStateToProps)(Navbar);

